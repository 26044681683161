


















































import { Component, Vue } from 'vue-property-decorator'
import { activePropertiesStatus } from '@/api/statistics'
import { redirectToEdit } from '@/utils/property'

@Component({
  name: 'SpitogatosStatistics',
  components: {}
})

export default class extends Vue {
  private total = 0
  private page = 1
  private pageSize = 10
  private list = []
  private listLoading = false
  private redirectToEdit = redirectToEdit

  created() {
    this.getList()
  }

  get pagedTableData() {
    return this.list.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
  }

  private setPage(val: number) {
    this.page = val
  }

  private getRejectionReason(reason: string) {
    if (reason === 'inactive_service') {
      return this.$t('statistics.inactive_service')
    }
    return reason
  }

  private async getList() {
    this.listLoading = true
    try {
      const { data } = await activePropertiesStatus({})
      this.list = data
      this.total = data.length
    } catch (err) {}
    this.listLoading = false
  }
}
