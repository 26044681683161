



























































import { Component, Vue } from 'vue-property-decorator'
import Todo, { ITodo } from './Todo.vue'
import { deleteTask, getTasks, saveTask, saveTaskBulk } from '@/api/tasks'

const filters: { [ key: string ]: Function } = {
  all: (todos: ITodo[]) => todos,
  active: (todos: ITodo[]) => todos.filter(todo => !todo.done),
  completed: (todos: ITodo[]) => todos.filter(todo => todo.done)
}

@Component({
  name: 'TodoList',
  components: {
    Todo
  },
  filters: {
    pluralize: (n: number, s: string) => n === 1 ? s : s + 's',
    capitalize: (s: string) => s.charAt(0).toUpperCase() + s.slice(1)
  }
})
export default class extends Vue {
  private visibility = 'all'
  private todos: any[] = []

  get allChecked() {
    return this.todos.every(todo => todo.done)
  }

  get filters() {
    return filters
  }

  get filteredTodos() {
    return filters[this.visibility](this.todos)
  }

  get remaining() {
    return this.todos.filter(todo => !todo.done).length
  }

  created() {
    getTasks({}).then(({ data }) => {
      this.todos = data.collection
    })
  }

  private addTodo(e: KeyboardEvent) {
    const text = (e.target as HTMLInputElement).value
    if (text.trim()) {
      this.todos.push({
        description: text,
        done: false
      })
      saveTask(this.todos[this.todos.length - 1])
    }
    (e as any).target.value = ''
  }

  private toggleTodo(todo: ITodo) {
    todo.done = !todo.done
    saveTask(todo)
  }

  private deleteTodo(todo: ITodo) {
    this.todos.splice(this.todos.indexOf(todo), 1)
    deleteTask({
      id: todo.id
    })
  }

  private editTodo({ todo, value }: { todo: ITodo, value: string }) {
    todo.description = value
    saveTask(todo)
  }

  private clearCompleted() {
    this.todos = this.todos.filter(todo => !todo.done)
    saveTaskBulk(this.todos)
  }

  private toggleAll({ done }: { done: boolean }) {
    this.todos.forEach(todo => {
      todo.done = done
    })
    saveTaskBulk(this.todos)
  }
}
