


































































































import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import { getProperties } from '@/api/properties'
import { createFilter, FilterOperator, FilterType, SortOrder } from '@/utils/filter'
import { getFloorTitle, prepareDashboardFilters } from '@/utils/property'
import { getAreaFullTitle, parseTime } from '@/utils'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'OfficeNewPropertiesTable',
  components: {
    Pagination
  },
  filters: {
    toThousandFilter: (num: number) => {
      return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
    }
  }
})
export default class extends Vue {
  @Prop({ required: false }) private range!: any
  @Prop({ required: true }) private type!: string

  get items() {
    switch (this.type) {
      case 'approved':
      case 'sent_for_approval': {
        return [{
          type: FilterType.field,
          key: 'status',
          value: this.type,
          operator: FilterOperator.eq
        }]
      }
      case 'new_properties': {
        return [{
          type: FilterType.field,
          key: 'status',
          value: 'approved',
          operator: FilterOperator.eq
        }, {
          type: FilterType.operator,
          operator: FilterOperator.or,
          items: [{
            type: FilterType.field,
            key: 'assignation_state',
            value: 'active',
            operator: FilterOperator.eq
          }, {
            type: FilterType.field,
            key: 'assignation_state',
            value: 'under_negotiation',
            operator: FilterOperator.eq
          }, {
            type: FilterType.field,
            key: 'assignation_state',
            value: 'under_offer_status',
            operator: FilterOperator.eq
          }]
        }]
      }
    }
    return []
  }

  get rangeKey() {
    switch (this.type) {
      case 'new_properties':
      case 'approved': {
        return 'confirmation_date'
      }
      case 'sent_for_approval': {
        return 'date_updated'
      }
      default: {
        return 'date_created'
      }
    }
  }

  private listQuery = createFilter(this.items, {
    page_size: 10,
    sort: [{
      field: 'confirmationDate',
      order: SortOrder.desc
    }]
  })

  private list: any[] = []
  private total = 0
  private listLoading = true
  private getAreaFullTitle = getAreaFullTitle
  private parseTime = parseTime

  created() {
    this.getList()
  }

  @Watch('range', { deep: true })
  private onRangeChange() {
    this.getList()
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/property/edit/' + id)
  }

  private async getList() {
    this.listLoading = true
    try {
      prepareDashboardFilters(this.listQuery, this.range, this.rangeKey)
      const { data } = await getProperties(this.listQuery)
      this.list = data.collection
      this.total = data.pagination.total_items
    } catch (err) {}
    this.listLoading = false
  }

  private getFloor(row: any) {
    const value = getFloorTitle(row)
    if (typeof value === 'string') return this.$t(value)
    return value
  }
}
