



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TimeRange } from '@/models/TimeRange'

@Component({
  name: 'Client'
})

export default class extends Vue {
  @Prop({ default: new TimeRange() }) private range!: TimeRange

  private dateFromPickerOptions = {
    disabledDate: (date: any) => {
      if (!this.range.to) return false
      return date >= this.range.to
    }
  }

  private dateToPickerOptions = {
    disabledDate: (date: any) => {
      if (!this.range.from) {
        return date > new Date()
      }
      return date > new Date() || date <= this.range.from
    }
  }
}
