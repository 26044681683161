import request from '@/utils/request'

export const getTasks = (data: any) =>
  request({
    method: 'post',
    url: '/api/tasks/getTasks',
    data
  })

export const saveTask = (data: any) =>
  request({
    method: 'post',
    url: '/api/tasks/saveTask',
    data
  })

export const saveTaskBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/tasks/saveTaskBulk',
    data
  })

export const deleteTask = (params: any) =>
  request({
    method: 'get',
    url: '/api/tasks/deleteTask',
    params
  })

export const deleteTaskBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/tasks/deleteTaskBulk',
    data
  })
