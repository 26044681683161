




































import {
  Component,
  Vue
} from 'vue-property-decorator'
import { getNews } from '@/api/news'
import { createFilter, FilterOperator, FilterType, SortOrder } from '@/utils/filter'
import { getLocale } from '@/lang'
import { parseTime } from '@/utils'
@Component({
  name: 'NewsGroup'
})

export default class extends Vue {
  private listQuery = createFilter()
  private parseTime = parseTime
  private updates: any[] = []

  mounted() {
    this.listQuery.query?.filters?.items?.push({
      type: FilterType.field,
      key: 'locale',
      value: getLocale(),
      operator: FilterOperator.eq
    })
    this.listQuery.query?.sort?.push({
      field: 'date_created',
      order: SortOrder.desc
    })
    this.getList()
  }

  async getList() {
    try {
      const { data } = await getNews(this.listQuery)
      this.$set(this, 'updates', data.collection)
    } catch (err) {}
  }
}
