







import * as echarts from 'echarts'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import ResizeMixin from '@/components/Charts/mixins/resize'
import { getRandomColor } from '@/utils'

@Component({
  name: 'LineChart'
})

export default class extends mixins(ResizeMixin) {
  @Prop({ required: true }) private chartData!: any
  @Prop({ default: 'chart' }) private className!: string
  @Prop({ default: '100%' }) private width!: string
  @Prop({ default: '400px' }) private height!: string

  @Watch('chartData', { deep: true })
  private onChartDataChange(value: any) {
    this.setOptions(value)
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  }

  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'macarons')
    this.setOptions(this.chartData)
  }

  private getColor(key: string) {
    switch (key) {
      case 'assignments': {
        return '#E6A17C'
      }
      case 'visits': {
        return '#81C179'
      }
      case 'searches': {
        return '#41949F'
      }
      default: {
        return getRandomColor()
      }
    }
  }

  private setOptions(chartData: any) {
    if (this.chart) {
      const chartDataKeys = Object.keys(chartData)
      const xAxisHeaders = chartData[chartDataKeys[0]].map((item: any) => {
        return item.header
      })
      const legend = {
        data: chartDataKeys.map((key: string) => {
          return this.$t('dashboard.' + key)
        })
      }
      this.chart.setOption({
        xAxis: {
          data: xAxisHeaders,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: 8
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: legend,
        series: chartDataKeys.map((key: string) => {
          return {
            name: this.$t('dashboard.' + key),
            itemStyle: {
              color: this.getColor(key),
              lineStyle: {
                color: '#FF005A',
                width: 5
              }
            },
            smooth: true,
            type: 'line',
            data: chartData[key],
            animationDuration: 2800,
            animationEasing: 'cubicInOut'
          }
        })
      })
    }
  }
}
