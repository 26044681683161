var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"new-properties-table",attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.full_code))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.area')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getAreaFullTitle({property: row})))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("toThousandFilter")(row.price))+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.acreage')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.acreage))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.floor')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getFloor(row)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.views')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.views))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.confirmationDate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.confirmation_date)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.actions'),"width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.redirectToEdit(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.intrahome'))+" ")])]}}])})],1),_c('pagination',{attrs:{"hide-on-single-page":true,"layout":"total, prev, pager, next","total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size,"autoScroll":false},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }